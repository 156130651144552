<template>
    <div class="information">
        <div class="information-name">
            <div>
                <span>{{sendForm.nickName}}</span>
                <i v-if="sendForm.sex == 'men'" class="iconfont icon-xingbienan1"></i>
                <i v-else style="color:#FF3EC9" class="iconfont icon-xingbienv1"></i>
                <span v-if="sendForm.level == 0">{{$t('class.vip1')}}</span>
                <span v-if="sendForm.level == 1">{{$t('class.vip2')}}</span>
                <span v-if="sendForm.level == 2">{{$t('class.vip3')}}</span>
            </div>
            <div v-if="sendForm.level == 1 || sendForm.level == 2">
                <span>{{$t('class.text')}}：</span>
                <span v-if="sendForm.vipDate">{{$isPulic.timestampToTimeccc(sendForm.vipDate)}}</span>
            </div>
        </div>
        <div class="information-text">
            <div v-if="sendForm.year">{{sendForm.year}}-{{sendForm.month}}</div>
            <div v-if="sendForm.height">{{sendForm.height}}CM</div>
            <div v-if="sendForm.weight">{{sendForm.weight}}KG</div>
            <div v-if="sendForm.country">{{sendForm.country}}-{{sendForm.city}}</div>
        </div>
        <div class="information-test">
            <div v-if="sendForm.year || sendForm.month">{{sendForm.year}}-{{sendForm.month}}-{{sendForm.day}}</div>
            <div v-if="sendForm.education">{{sendForm.education}}</div>
            <div v-if="sendForm.occupation">{{sendForm.occupation}}</div>
            <div v-if="sendForm.income">{{$t('sendUser.income')}}：{{sendForm.income}}</div>
        </div>
        <div class="information-zi">
            <div>{{$t('userTop.title')}}</div>
            <div>
                <div class="information-zi-c">
                    <span>
                        <i class="iconfont icon-bussiness-man"></i>
                        <span>{{$t('sendUser.realName')}}：</span>
                    </span>
                    <span v-if="sendForm.realName">{{sendForm.realName}}</span>
                    <span v-else>{{$t('sendUser.noText')}}</span>
                </div>
                <div class="information-zi-c">
                    <span>
                        <i class="iconfont icon-component"></i>
                        <span>{{$t('sendUser.cardID')}}：</span>
                    </span>
                    <span v-if="sendForm.cardID">{{sendForm.cardID}}</span>
                    <span v-else>{{$t('sendUser.noText')}}</span>
                </div>
            </div>
        </div>
        <div class="information-tishi">{{$t('userTop.text')}}<span style="color:#f00">{{$t('userTop.textGo')}}</span>！</div>
        <div class="information-money">
            <i class="iconfont icon-Dollar-circle-fill"></i>
            <span>{{$t('userTop.balance')}}：</span>
            <span>{{sendForm.balance}}</span>
            <!--
            <span>
                <span>{{$t('userTop.already')}}</span>
                <span style="color:rgba(255,0,0,.6);margin:0 6px">**</span>
                <span>{{$t('userTop.visit')}}</span>
            </span>
            -->
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            cccdata: {imgType:'chatImg'},
            isRouterAlive: true,
            dialogImageUrl: "",
            dialogVisible: false,
            sendForm: {},
            imgs: [],
            stateForm: {
                userId: "",
                content: "",
                fileUrl: ""
            }
        }
    },
    created(){
        this.$isAxios.axiosGet(this.$isApi.showUserInfo,{userId:JSON.parse(sessionStorage.getItem('sendForm')).userId},(res)=>{
            this.sendForm = res.data.result
        })
        this.stateForm.userId = JSON.parse(sessionStorage['sendForm']).userId
    }
}
</script>

<style scoped>
.information{
    background: #fff;
    height: max-content;
    padding: 16px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1)
}
.information-name{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.information-name>div:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
}
.information-name>div:nth-child(1)>span:nth-child(1){
    font-size: 20px;
    font-weight: bold;
    color: #333;
}
.information-name>div:nth-child(1)>i{
    font-size: 26px;
    font-weight: bold;
    display: flex;
    color: #588EFF;
    margin-left: 10px;
}
.information-name>div:nth-child(1)>span:nth-child(3){
    font-size: 13px;
    display: block;
    padding-top: 5px;
    color: rgba(255, 0, 0, .6);
    margin-left: 6px;
    font-weight: bold;
    cursor: pointer;
}
.information-name>div:nth-child(1)>span:nth-child(3):hover{
    color: #f00;
}
.information-name{
    font-size: 14px;
    color: #999;
}
.information-text{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px
}
.information-text>div{
    height: 20px;
    line-height: 20px;
    width: max-content;
    padding: 0 10px;
    background: #588EFF;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 12px;
    font-size: 12px;
    color: #fff;
}
.information-test{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px
}
.information-test>div{
    margin-right: 12px;
    padding-right: 12px;
    width: max-content;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    color: #666;
    border-right: 1px solid #999
}
.information-test>div:last-child{
    border: none
}

.information-zi{
    margin-top: 16px;
}
.information-zi>div:nth-child(1){
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 18px
}
.information-zi>div:nth-child(2),
.information-zi>div:nth-child(3){
    display: flex;
    margin-top: 10px
}
.information-zi-c{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.information-zi-c>span{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #999;
}
.information-zi-c>span:nth-child(1)>i{
    font-size: 26px;
    color: #999;
    display: block;
    width: 28px;
    height: 28px;
}
.information-zi-c>span:nth-child(2){
    font-size: 14px;
    color: #316FF5;
    cursor: pointer;
}
.information-zi-c>span:nth-child(2):hover{
    text-decoration: underline
}
.information-tishi{
    background: rgba(255, 0, 0, .08);
    padding: 10px 14px;
    font-size: 12px;
    margin-top: 16px
}
.information-money{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 16px
}
.information-money>i{
    display: block;
    height: 26px;
    width: 26px;
    color: #f00;
    font-size: 26px;
    text-align: center;
    line-height: 26px
}
.information-money>span:nth-child(2){
    font-size: 14px;
    color: #999;
    margin-left: 10px
}
.information-money>span:nth-child(3){
    font-weight: bold;
    font-size: 14px;
    color: #f00;
    flex: 1
}
.information-money>span:nth-child(4){
    font-size: 12px;
}
</style>
