<template>
    <div class="user-photo">
        <div class="user-photo-title">
            <i class="iconfont icon-picture"></i>
            <span>{{$t('userLeft.myPhoto')}}</span>
            <span class="more" @click="goPhoto">{{$t('newState.more')}}</span>
        </div>
        <div class="user-photo-list">
            <div v-for="(item,index) in photoList.slice(0,4)" :key="index">
                <div>
                    <div>
                        <div>
                            <div>
                                <img :src="item.fileUrl" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div>{{$isPulic.timestampToTime(item.createDate)}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            photoList: []
        }
    },
    created(){
        this.$isAxios.axiosGet(this.$isApi.showAll,{userId: JSON.parse(sessionStorage['sendForm']).userId},(res)=>{
            if(res.data.result){
                this.photoList = res.data.result
            }
        })
    },
    methods: {
        goPhoto(){
            this.$router.push({
                path: "/page/user/photo"
            })
        }
    }
}
</script>

<style scoped>
.user-photo{
    margin-top: 20px;
    background: #fff;
    padding: 16px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1)
}
.user-photo-title{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.user-photo-title>i{
    font-size: 24px;
    display: block;
    font-weight: bold;
    color: #f00;
    margin-right: 8px
}
.user-photo-title>span:nth-child(2){
    font-size: 14px;
    color: #333;
    font-weight: bold;
    flex: 1
}
.user-photo-list{
    display: flex;
    margin-top: 20px;
    padding-left: 6px
}
.user-photo-list>div{
    margin-right: 10px;
    width: max-content;
    height: max-content
}
.user-photo-list>div>div:nth-child(1){
    position: relative;
    width: 140px;
    height: 160px;
    background: #eee;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .1);
    border: 1px solid #ccc
}
.user-photo-list>div>div:nth-child(1)>div{
    width: 140px;
    height: 160px;
    position: absolute;
    bottom: 1px;
    right: 1px;
    background: #eee;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
    border: 1px solid #ccc;
    z-index: 9;
}
.user-photo-list>div>div:nth-child(1)>div>div{
    width: 140px;
    height: 160px;
    position: absolute;
    bottom: 1px;
    right: 1px;
    background: #eee;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
    border: 1px solid #ccc;
    z-index: 99;
}
.user-photo-list>div>div:nth-child(1)>div>div>div{
    width: 126px;
    height: 147px;
    position: absolute;
    bottom: 1px;
    right: 1px;
    background: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
    border: 1px solid #ccc;
    z-index: 999;
    padding: 7px 6px;
}
.user-photo-list>div>div:nth-child(1)>div>div>div>img{
    width: 100%;
    height: 100%;
    background: #666;
    object-fit: cover;
}
.user-photo-list>div>div:nth-child(2){
    font-size: 12px;
    color: #999;
    margin-top: 6px
}
</style>
