<template>
    <div class="user-info">
        <div class="user-info-title">
            <i class="iconfont icon-bussiness-man"></i>
            <span>{{$t('sendUser.myContent')}}</span>
            <span class="more" @click="goUserXQ">{{$t('sendUser.perfect')}}</span>
        </div>
        <div class="user-info-center">
            <div>
                <div>{{$t('sendUser.userName')}}</div>
                <div>{{sendForm.userName}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.realName')}}</div>
                <div v-if="sendForm.realName">{{sendForm.realName}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.cardID')}}</div>
                <div v-if="sendForm.cardID">{{sendForm.cardID}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.marriageStatus')}}</div>
                <div v-if="sendForm.marriageStatus">{{sendForm.marriageStatus}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <!--
            <div>
                <div>{{$t('sendUser.line')}}</div>
                <div v-if="sendForm.line">{{sendForm.line}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            -->
            <div>
                <div>{{$t('sendUser.city')}}</div>
                <div v-if="sendForm.city">{{sendForm.city}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.height')}}</div>
                <div v-if="sendForm.height">{{sendForm.height}}CM</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.weight')}}</div>
                <div v-if="sendForm.weight">{{sendForm.weight}}KG</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.age')}}</div>
                <div v-if="age">{{age}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.education')}}</div>
                <div v-if="sendForm.education">{{sendForm.education}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.signature')}}</div>
                <div v-if="sendForm.signature">{{sendForm.signature}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
            <div>
                <div>{{$t('sendUser.income')}}</div>
                <div v-if="sendForm.income">{{sendForm.income}}</div>
                <div v-else style="color:#f00">{{$t('sendUser.noText')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            sendForm: {},
            age: 0
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        var age = this.sendForm.year + "-" + this.sendForm.month + "-" + this.sendForm.day
        this.age = this.$isPulic.age_Conversion(age)
    },
    methods: {
        goUserXQ(){
            this.$router.push({
                path: "/page/user/details"
            })
        }
    }
}
</script>

<style scoped>
.user-info{
    margin-top: 20px;
    background: #fff;
    padding: 16px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1)
}
.user-info-title{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.user-info-title>i{
    font-size: 24px;
    display: block;
    font-weight: bold;
    color: #f00;
    margin-right: 8px
}
.user-info-title>span:nth-child(2){
    font-size: 14px;
    color: #333;
    font-weight: bold;
    flex: 1
}
.user-info-center{
    margin-top: 6px;
    padding: 0 6px
}
.user-info-center>div{
    display: flex;
    height: max-content;
    font-size: 14px;
    padding: 10px 0;
    border-bottom: 1px dashed #ccc;
    flex-direction: row;
    align-items: center;
}
.user-info-center>div:last-child{
    border: none
}
.user-info-center>div>div:nth-child(1){
    flex-grow: 0;
    width: 90px;
    text-align: right;
    color: #999;
    /* text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; */
    margin-right: 10px
}
.user-info-center>div>div:nth-child(2){
    width: calc( 100% - 90px );
    flex-grow: 0;
    height: max-content;
    color: #333;
}
</style>
