<template>
    <div>
        <user-top></user-top>
        <user-photo></user-photo>
        <user-info></user-info>
    </div>
</template>

<script>
import UserTop from '../../../components/user/userTop.vue'
import UserPhoto from '../../../components/user/userPhoto.vue'
import UserInfo from '../../../components/user/userInfo.vue'
export default {
    components: {UserTop,UserPhoto,UserInfo},
    data(){
        return{
            
        }
    },
    created(){
        this.$isPulic.crtime((res,time)=>{
            this.createdTime = time
        })
    }
}
</script>